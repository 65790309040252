import React, { Fragment } from "react";
import { Carousel } from "react-bootstrap";
import Img1 from "../Images/image1.jpg";
import Img2 from "../Images/image2.jpg";
import Img3 from "../Images/imge3.jpg";
import Img4 from "../Images/image 4 (1).jpg";
import Img5 from "../Images/image 4 (10).jpg";
import Img6 from "../Images/image 4 (2).jpg";
import Img7 from "../Images/image 4 (3).jpg";
import Img8 from "../Images/image 4 (4).jpg";
import Img9 from "../Images/image 4 (5).jpg";
import Img10 from "../Images/image 4 (6).jpg";
import Img11 from "../Images/image 4 (7).jpg";
import Img12 from "../Images/image 4 (8).jpg";
import Img13 from "../Images/image 4 (9).jpg";

import "../Styles/Header.css";

function Header() {
  return (
    <Fragment>
      <Carousel variant="dark" style={{ maxHeight: "650px" }}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img1}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img2}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img3}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img4}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img5}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img6}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img7}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img8}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img9}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img10}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img11}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img12}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            // style={{ height: "100vh", objectFit: "cover" }}
            src={Img13}
            alt="pure pu ladies bag"
          />
        </Carousel.Item>
      </Carousel>
    </Fragment>
  );
}

export default Header;
