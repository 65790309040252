import "../Styles/Footer.css"; // Import your CSS file
import Facebook from "../Images/facebook (1).png";
import instagram from "../Images/instagram.png";
import tiktok from "../Images/tik-tok.png";
import snapchat from "../Images/snapchat.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-wrapper">
      {/* Other components like header and main content */}
      <footer>
        <div className="footer-containertop">
          {t("Visit our social networks")}
        </div>
        <div className="all">
          <div className="footer-container">
            <div className="footer-column">
              <h4>
                <a
                  href="https://www.facebook.com/TechTopia1998"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="facebook" className="image_footer" />
                </a>
              </h4>
              <p></p>
            </div>

            <div className="footer-column">
              <h4>
                <a
                  href="https://www.facebook.com/TechTopia1998"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="instagram"
                    className="image_footer"
                  />
                </a>
              </h4>
              <p></p>
            </div>

            <div className="footer-column">
              <h4>
                <a
                  href="https://www.facebook.com/TechTopia1998"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tiktok} alt="tiktok" className="image_footer" />
                </a>
              </h4>
              <p></p>
            </div>

            <div className="footer-column">
              <h4>
                <a
                  href="https://www.facebook.com/TechTopia1998"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={snapchat} alt="facebook" className="image_footer" />
                </a>
              </h4>
              <p></p>
            </div>
          </div>
          <hr className="footer-hr" />
          <div className="footer-bottom">
            {/* <span>&copy; 2024 Bardawam Plus</span> */}
            <p>
              This website created by
              <a
                href="https://www.facebook.com/TechTopia1998"
                target="_blank"
                style={{ color: "#05eeff" }}
                rel="noreferrer"
              >
                TechTopia
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
