import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "../Styles/Mobile.css";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ViewListIcon from "@mui/icons-material/ViewList";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faSnapchatGhost,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import Arabic from "../Images/iraq.png";
import Kurdistan from "../Images/flag.png";
import UK from "../Images/united-states-of-america.png";
import i18n from "../../i18n";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("selectedTheme") === "dark"
  );
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-theme", darkMode ? "dark" : "light");
    localStorage.setItem("selectedTheme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const onChangeLang = (selectedLang) => {
    Cookies.set("language", selectedLang);
    i18n.changeLanguage(selectedLang);
  };

  const handleLanguageClick = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorElLanguage(null);
    setOpenDrawer(false);
  };

  const handleDarkModeToggle = () => {
    setDarkMode((prev) => !prev);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "var(--drawer-bg)", // Use CSS variable
            color: "var(--drawer-text)", // Use CSS variable
          },
          className: "drawer", // Add className
        }}
      >
        <List>
          <ListItemButton
            component={Link}
            to="/"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>{t("Home")}</ListItemText>
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/AllProducts"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText>{t("Products")}</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={handleLanguageClick}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText>{t("Language")}</ListItemText>
            <ExpandMoreIcon />
          </ListItemButton>
          <Menu
            anchorEl={anchorElLanguage}
            open={Boolean(anchorElLanguage)}
            onClose={handleLanguageClose}
          >
            <MenuItem onClick={() => onChangeLang("ar")}>
              <img src={Arabic} alt="Arabic Flag" height={25} width={25} />
              {t("Arabic")}
            </MenuItem>
            <MenuItem onClick={() => onChangeLang("ku")}>
              <img
                src={Kurdistan}
                alt="Kurdistan Flag"
                height={25}
                width={25}
              />
              {t("Kurdish")}
            </MenuItem>
            <MenuItem onClick={() => onChangeLang("en")}>
              <img src={UK} alt="UK Flag" height={25} width={25} />
              {t("English")}
            </MenuItem>
          </Menu>

          <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
            <IconButton
              onClick={handleDarkModeToggle}
              sx={{ color: darkMode ? "#ffb74d" : "#424242" }}
            >
              {darkMode ? "🌙" : "☀️"}
            </IconButton>
          </Box>
        </List>
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/TechTopia1998", "_blank")
            }
            sx={{ color: "#3b5998" }}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://www.instagram.com/TechTopia1998", "_blank")
            }
            sx={{ color: "#c13584" }}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=9647811421542",
                "_blank"
              )
            }
            sx={{ color: "#25D366" }}
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://www.snapchat.com/add/TechTopia", "_blank")
            }
            sx={{ color: "#fffc00" }}
          >
            <FontAwesomeIcon icon={faSnapchatGhost} />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open("https://www.tiktok.com/@TechTopia", "_blank")
            }
            sx={{ color: "#000000" }}
          >
            <FontAwesomeIcon icon={faTiktok} />
          </IconButton>
        </Box>
      </Drawer>
      <IconButton
        sx={{
          color: darkMode ? "#fff" : "#000",
          marginLeft: "auto",
          fontWeight: "bold",
        }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
