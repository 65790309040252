import React, { useEffect, useState } from "react";
import {
  AppBar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Toolbar,
  useMediaQuery,
  useTheme,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import "../Styles/Navber.css";
import logo from "../Images/Logo (2).png";
import iraq from "../Images/iraq.png";
import Kurdistan from "../Images/flag.png";
import UK from "../Images/united-states-of-america.png";
import HomeIcon from "@mui/icons-material/Home";
import ViewListIcon from "@mui/icons-material/ViewList";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DrawerComp from "./Drawer";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Navbar = () => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("selectedTheme") === "dark"
  );
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState("");
  const { t } = useTranslation();

  const onChangeLang = (selectedLang) => {
    setLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    Cookies.set("language", selectedLang);
    // localStorage.removeItem("i18nextLng");
  };

  useEffect(() => {
    const savedLang = Cookies.get("language"); // Use 'language' instead of 'selectedLanguage'
    if (savedLang) {
      setLanguage(savedLang);
      i18n.changeLanguage(savedLang); // Set the saved language from cookies
    }
  }, []);

  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-theme", darkMode ? "dark" : "light");
    localStorage.setItem("selectedTheme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const TabLabelWithIcon = ({ label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {label} <ArrowDropDownIcon />
    </div>
  );

  return (
    <React.Fragment>
      <AppBar
        sx={{
          backgroundColor: "aliceblue", // Adjust based on the theme
          color: "black",
          fontWeight: "bold",
          fontSize: "16px",
        }}
      >
        <Toolbar>
          {isMatch ? (
            <>
              <div className="class-logo">
                <img src={logo} alt="Logo" height={50} width={50} />
              </div>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="class-logo">
                <img src={logo} alt="Logo" height={50} width={50} />
              </div>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
                <Tab
                  component={Link}
                  to="/"
                  label={t("Home")}
                  icon={<HomeIcon />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold" }}
                />
                <Tab
                  component={Link}
                  to="/AllProducts"
                  label={t("Products")}
                  icon={<ViewListIcon />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold" }}
                />
                <Tab
                  label={<TabLabelWithIcon label={t("Language")} />}
                  icon={<LanguageIcon />}
                  iconPosition="start"
                  onClick={handleLanguageClick}
                  sx={{ fontWeight: "bold" }}
                />
              </Tabs>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleLanguageClose}
              >
                <MenuItem
                  onClick={() => {
                    onChangeLang("ar");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img src={iraq} alt="Iraq Flag" height={30} width={30} />
                  </ListItemIcon>
                  {t("Arabic")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onChangeLang("ku");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={Kurdistan}
                      alt="Kurdistan Flag"
                      height={25}
                      width={25}
                    />
                  </ListItemIcon>
                  {t("Kurdish")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onChangeLang("en");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img src={UK} alt="UK Flag" height={30} width={30} />
                  </ListItemIcon>
                  {t("English")}
                </MenuItem>
              </Menu>
              <Box
                sx={{ display: "flex", justifyContent: "center", padding: 2 }}
              >
                <IconButton
                  onClick={() => setDarkMode((prev) => !prev)}
                  sx={{ color: darkMode ? "#ffb74d" : "#424242" }}
                >
                  {darkMode ? "🌙" : "☀️"}
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;
