import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Navbar from "./Frontend/Navbar/Navbar";
import Home from "./Frontend/Pages/Home";
import AllProducts from "./Frontend/Products/Allproducts";
import ProductBYid from "./Frontend/Categories/ProductBYid";
import Category from "./Frontend/Categories/Category";
import LanguageSelection from "./LanguageSelection";
import i18n from "./i18n";
import NotFound from "./Frontend/Pages/NoFound";
import Cookies from "js-cookie";

function App() {
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const language = Cookies.get("language");
    if (language) {
      setIsLanguageSelected(true);
      i18n.changeLanguage(language); // Set the selected language
      localStorage.removeItem("i18nextLng");
    } else {
      navigate("/select-language"); // Redirect to language selection if no language is selected
    }
  }, [navigate]);

  return (
    <div>
      {isLanguageSelected && location.pathname !== "/select-language" && (
        <Navbar />
      )}
      <Routes>
        <Route path="/select-language" element={<LanguageSelection />} />
        <Route path="/" element={<Home />} />
        <Route path="/AllProducts" element={<AllProducts />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/category/:categoryId" element={<ProductBYid />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
