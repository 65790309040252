import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../Styles/ProductBYid.css";
import Footer from "../Pages/Footer";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function
import SliderCategory from "./SliderCategory";
const ProductBYid = () => {
  const { categoryId } = useParams(); // Get category ID from the URL
  const [products, setProducts] = useState([]);
  const { t, i18n } = useTranslation();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const getProductsByCategoryId = async (categoryId) => {
    try {
      const res = await fetch(`https://menu-api.webappdevs.com/api/products`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const response = await res.json();
      const filteredProducts = response.data.filter(
        (p) => p.category_id === Number(categoryId) // Ensure categoryId is a number
      );
      setProducts(filteredProducts); // Set the filtered products
      console.log("filteredProducts", filteredProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (categoryId) {
      getProductsByCategoryId(categoryId); // Pass the categoryId to the function
    }
  }, [categoryId]);
  const currentLanguage = i18n.language || "en";
  const direction = ["ar", "ku"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ar", "ku"].includes(i18n.language) ? "right" : "left";
  return (
    <>
      <div className="mb-4 pb-4" />
      <SliderCategory />
      <Container
        className="product-container"
        style={{
          direction,
          textAlign,
        }}
      >
        <div className="mb-5 pb-5" />

        {products.length > 0 ? (
          <Row>
            {products.map((product) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={product.id}
                className="mb-4"
              >
                <Card className="product-card">
                  <Card.Img
                    // variant="top"
                    src={`https://menu-api.webappdevs.com/storage/${product.image_url}`}
                    alt={product.name}
                    className="card-img-top"
                  />
                  <Card.Body>
                    <Card.Title className="card-title">
                      {JSON.parse(product.name)[currentLanguage] || "N/A"}
                    </Card.Title>
                    <Card.Text className="card-text">
                      {JSON.parse(product.description)[currentLanguage] ||
                        "N/A"}
                    </Card.Text>
                    <div className="price-container">
                      <Card.Text className="discounted-price">
                        {product.discounted_price} IQD
                      </Card.Text>
                      <Card.Text className="price">
                        {product.price} IQD
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="no-products-message">
            {t("No products are available for this category")}
          </div>
        )}
        {showScrollButton && (
          <Button
            type="primary"
            className="scroll-to-top-button"
            onClick={scrollToTop} // Add onClick handler for scrolling to top
          >
            <ArrowUpOutlined />
          </Button>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ProductBYid;
