import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "./i18n";
import "./Frontend/Styles/LanguageSelection.css";
import Cookies from "js-cookie";

function LanguageSelection() {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedLanguage) {
      // Change the language and store it in cookies
      i18n.changeLanguage(selectedLanguage);
      Cookies.set("language", selectedLanguage);

      // Remove the language from localStorage if it exists
      localStorage.removeItem("i18nextLng");

      // Navigate after a short delay to allow the language change to complete
      setTimeout(() => {
        navigate("/");
      }, 100); // Short delay before navigation
    }
  }, [selectedLanguage, navigate]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language); // This triggers the useEffect
  };

  return (
    <div className="language-selection-container">
      <div className="stars">
        {Array.from({ length: 100 }).map((_, i) => (
          <div
            key={i}
            className="star"
            style={{
              "--top-offset": `${Math.random() * 100}vh`,
              "--star-color": `rgba(255, 255, 255, ${Math.random()})`,
              "--fall-delay": `${Math.random() * 10}s`,
            }}
          />
        ))}
      </div>
      <h1 className="language-selection-title">Select Your Language</h1>
      <div className="language-buttons">
        <button
          className="language-button english"
          onClick={() => handleLanguageChange("en")}
        >
          English
        </button>
        <button
          className="language-button kurdish"
          onClick={() => handleLanguageChange("ku")}
        >
          Kurdish
        </button>
        <button
          className="language-button arabic"
          onClick={() => handleLanguageChange("ar")}
        >
          Arabic
        </button>
      </div>

      <footer className="language-selection-footer">
        <a
          href="https://www.facebook.com/TechTopia1998"
          target="_blank"
          rel="noopener noreferrer"
          className="facebook-link"
          aria-label="Visit our Facebook page"
        >
          <span>TechTopia</span>
        </a>
      </footer>
    </div>
  );
}

export default LanguageSelection;
