import AllCategories from "../Categories/AllCategories";
// import SliderCategory from "../Categories/SliderCategory";
import Footer from "./Footer";
import Header from "./Header";

const Home = () => {
  return (
    <div>
      <div className="mb-4 pb-4" />
      <Header />
      <div className="mb-3 pb-3" />
      <AllCategories />

      <Footer />
    </div>
  );
};

export default Home;
