import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Category from "./Category";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function
const AllCategories = () => {
  const [category, setCategory] = useState([]);
  const { t, i18n } = useTranslation();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const getApiProducts = async () => {
    try {
      const res = await fetch(`https://menu-api.webappdevs.com/api/categories`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setCategory(
        data.map((item) => {
          // const nameObj = JSON.parse(item.name || "{}");
          const nameTranslations = JSON.parse(item.name);
          // const descriptionTranslations = JSON.parse(item.description);

          const currentLanguage = i18n.language || "en";
          const translatedName = nameTranslations[currentLanguage];
          const imageUrl = item.image_url
            ? `https://menu-api.webappdevs.com/storage/${item.image_url}`
            : null;

          return {
            key: item.id,
            id: item.id,
            image_url: imageUrl,
            name: translatedName,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getApiProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  return (
    <Container>
      <h3 className="text-center mb-4">{t("All Categories")}</h3>
      <Row>
        {category.map((p) => (
          <Col xs={6} sm={6} md={4} lg={2} className="mb-4" key={p.id}>
            <Category PP={p} />
          </Col>
        ))}
      </Row>
      {showScrollButton && (
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      )}
    </Container>
  );
};

export default AllCategories;
